<template>
  <v-row class="d-flex justify-center">
    <v-col cols="6">
      <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
        <v-card>
          <v-card-text>
            <v-autocomplete
              v-model="model"
              :items="entrys"
              :loading="loading"
              :search-input.sync="search"
              :value="inputValue"
              :placeholder="$t('customer name or number')"
              name="customer"
              item-text="text"
              item-value="value"
              prepend-icon="mdi-book-search-outline"
              @input="login"
              no-filter
              return-object
              auto-select-first
              hide-no-data
              eager
              autofocus
              clearable
              aria-autocomplete="none"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-list>
            <v-subheader>{{ $t("last customers") }}</v-subheader>
            <v-list-item
              v-for="(item, i) in last"
              :key="i"
              @click="login(item)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import reloadSite from "@/utils/mixins/reloadSite";
import showErrors from "@/utils/mixins/showErrors";

export default {
  name: "StaffAccountSwitch",
  mixins: [reloadSite, showErrors],
  props: {
    customerNumber: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    customers: [],
    model: [],
    last: [],
    inputValue: null,
    search: null,
    delayTimer: null,
  }),
  computed: {
    entrys() {
      var en = [];
      for (let i = 0; i < this.customers.length; i++) {
        en.push({
          text:
            this.customers[i].partner_type +
            " - " +
            this.customers[i].number +
            " - " +
            this.customers[i].name,
          value: this.customers[i].number,
        });
      }
      return en;
    },
  },
  watch: {
    customerNumber: {
      immediate: true,
      handler(value) {
        if (value) {
          var splited = String(value).split(":");
          var email = null;
          if (splited.length > 1) {
            value = splited[0];
            email = splited[1];
          }
          this.login({
            value: value,
            email: email,
          });
        }
      },
    },
    search(val) {
      var that = this;
      clearTimeout(that.delayTimer);
      that.delayTimer = setTimeout(function () {
        that.sendSearch();
      }, 850);
    },
    last(val) {
      localStorage.setItem("last", JSON.stringify(this.last));
    },
  },
  methods: {
    sendSearch() {
      var that = this;
      that.loading = true;
      this.$http
        .get("customers", {
          params: {
            rated: true,
            unfiltered: true,
            q: that.search,
          },
        })
        .then((response) => {
          that.customers = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    login(customer) {
      this.customers = [];
      for (let index = 0; index < this.last.length; index++) {
        if (this.last[index].value == customer.value) {
          this.last.splice(index, 1);
        }
      }
      this.last.unshift(customer);
      if (this.last.length > 20) {
        this.last.splice(20, 2);
      }
      var that = this;
      var params = { number: customer.value };
      if (customer.email) params.email = customer.email;
      this.loading = true;
      this.$http
        .post("login", params)
        .then(function (response) {
          that.$store.commit("login", response.data);
          that.reloadSite("home");
        })
        .catch(function (error) {
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    if (localStorage.getItem("last")) {
      this.last = JSON.parse(localStorage.getItem("last"));
    }
  },
};
</script>

<i18n>
{
  "en": {
    "customer name or number": "Customer name or number",
    "last customers": "Last customers"
  },
  "de": {
    "customer name or number": "Kundenname bzw. Nummer",
    "last customers": "Letzte Kunden"
  }
}
</i18n>
